import LogoSvg from './logoSvg';
import Day from './Day'
import './App.css';
import days from './dayData';

import Box from '@mui/material/Box';

function App() {
  return (
    <Box style={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }} className="App">
      <LogoSvg width="200" height="250"/>
      {days.map((day, index) => <Day number={index} day={day} />).reverse()}
    </Box>
  );
}

export default App;
