import React from 'react'

export default function logoSvg({ height, width }) {
  return (
    <svg viewBox="0 0 500 500" height={height} width={width}>
        <filter id="inset-shadow" x="-50%" y="-50%" width="200%" height="200%">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="1 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="30"/>
          <feOffset dx="20" dy="20" result="offsetblur"/>
          <feFlood flood-color="black" result="color"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
        <filter id="inset-shadow2" x="-50%" y="-50%" width="200%" height="200%">
          <feComponentTransfer in="SourceAlpha">
            <feFuncA type="table" tableValues="1 0" />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="5"/>
          <feOffset dx="3" dy="3" result="offsetblur"/>
          <feFlood flood-color="black" result="color"/>
          <feComposite in2="offsetblur" operator="in"/>
          <feComposite in2="SourceAlpha" operator="in" />
          <feMerge>
            <feMergeNode in="SourceGraphic" />
            <feMergeNode />
          </feMerge>
        </filter>
        <filter id="inset-shadow3" x="-50%" y="-50%" width="200%" height="200%">
        <feComponentTransfer in="SourceAlpha">
          <feFuncA type="table" tableValues="1 0" />
        </feComponentTransfer>
        <feGaussianBlur stdDeviation="3"/>
        <feOffset dx="2" dy="2" result="offsetblur"/>
        <feFlood flood-color="indigo" result="color"/>
        <feComposite in2="offsetblur" operator="in"/>
        <feComposite in2="SourceAlpha" operator="in" />
        <feMerge>
          <feMergeNode in="SourceGraphic" />
          <feMergeNode />
        </feMerge>
      </filter>
        <circle filter="url(#inset-shadow)" id="circle" cx="250" cy="250" r="250"/>
        <path id="blah" d="M75,250 a175,175 0 0,1 350,0" fill="#E79A16" />
        <text filter="url(#inset-shadow3)" width="500">
          <textPath startOffset="50%" text-anchor="middle" xlinkHref="#blah">
            New Things
          </textPath>
        </text>
        <text filter="url(#inset-shadow3)" id="logo-at" x="50%" y="30%" dominant-baseline="middle" text-anchor="middle">at</text>
        <text filter="url(#inset-shadow2)" id="logo-forty" x="50%" y="60%" dominant-baseline="middle" text-anchor="middle">40</text>
      </svg>
  )
}
