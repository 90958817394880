import React from 'react'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

const styles = {
  dayNumber: {
    display: 'flex',
    backgroundColor: 'indigo',
    justifyItems: 'stretch',
    alignItems: 'center',
    minHeight: '8em',
    width: '4em',
  },
  dayNumberText: {
    margin: 'auto',
    fontWeight: 700,
    fontSize: '2rem',
    color: 'lavender',
  },
  card: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'black',
    maxWidth: '90%',
    width: '32em',
    margin: '0.5em',
    borderRadius: '1em',
    alignItems: 'stretch',
    justifyItems: 'center'
  },
  details: {
    flex: 1,
    display: 'flex',
    backgroundColor: 'lavender',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '1em',
  }
};

export default function Day({ number, day }) {
  const { title, date, text } = day;
  const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
  return (
    <Card style={styles.card}>
      <Box style={styles.dayNumber}>
        <Typography style={styles.dayNumberText} variant='h4'>{number}</Typography>
      </Box>
      <Box style={styles.details}>
        <Typography style={{ color: 'indigo', alignSelf: 'flex-end' }} variant='caption'>{date.toLocaleDateString("en-UK", options)}</Typography>        
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='body'>{text}</Typography>
      </Box>
    </Card>
  )
}
