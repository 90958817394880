const days = [
  {
    title: 'The Birth of `New Things at 40`',
    text: '',
    date: new Date(2022, 0, 3),
  },
  {
    title: 'Turned 40',
    text: '',
    date: new Date(2022, 0, 4),
  },
  {
    title: 'Karting @ TeamSport Docklands',
    text: 'First Nokkel team outing',
    date: new Date(2022, 0, 5),
  },
  {
    title: 'British Airways Brewdog JetStream',
    text: '',
    date: new Date(2022, 0, 6),
  },
  {
    title: 'Meet a new person on Zoom',
    text: 'New business associate.',
    date: new Date(2022, 0, 7),
  },
  {
    title: 'Start Lego Technic Porsche 911',
    text: '',
    date: new Date(2022, 0, 8),
  },
  {
    title: 'Watched Clickbait on Netflix',
    text: '',
    date: new Date(2022, 0, 9),
  },
  {
    title: 'Baked cheese and jalapeno bread',
    text: '',
    date: new Date(2022, 0, 10),
  },
  {
    title: 'Netherlands government PCR Test',
    text: '',
    date: new Date(2022, 0, 11),
  },
  {
    title: 'Neleman\'s Signature red wine',
    text: '',
    date: new Date(2022, 0, 12),
  },
  {
    title: 'Beard Cape',
    text: '',
    date: new Date(2022, 0, 13),
  },
  {
    title: 'Fluxx',
    text: 'New game',
    date: new Date(2022, 0, 14),
  },
  {
    title: 'Whisky decanter',
    text: '',
    date: new Date(2022, 0, 15),
  },
  {
    title: 'Wordle',
    text: '',
    date: new Date(2022, 0, 16),
  },
  {
    title: 'Counting in Dutch',
    text: '',
    date: new Date(2022, 0, 17),
  },
  {
    title: 'Magnesium Daily Supplement',
    text: '',
    date: new Date(2022, 0, 18),
  },
  {
    title: 'Use Todoist',
    text: '',
    date: new Date(2022, 0, 19),
  },
  {
    title: 'Modern CTO',
    text: 'New podcast',
    date: new Date(2022, 0, 20),
  },
  {
    title: 'Overnight train to Basel',
    text: '',
    date: new Date(2022, 0, 21),
  },
  {
    title: 'Stay at the Boutique Hotel Glacier',
    text: 'Eat Flammkeuche',
    date: new Date(2022, 0, 22),
  },
  {
    title: 'Ski in Grindelwald, Switzerland',
    text: 'Zurich style veal; New ski goggles',
    date: new Date(2022, 0, 23),
  },
  {
    title: 'Ski up the Eiger',
    text: 'Eiger Express; ski at Wengen',
    date: new Date(2022, 0, 24),
  },
  {
    title: 'Tasting Menu @ Hotel Glacier',
    text: 'Sturgeon; Duck heart',
    date: new Date(2022, 0, 25),
  },
  {
    title: 'Real Swiss Fondue',
    text: 'Visited Basel',
    date: new Date(2022, 0, 26),
  },
  {
    title: 'Half-decent night\'s sleep on a train',
    text: 'Train from Basel to Amsterdam',
    date: new Date(2022, 0, 27),
  },
  {
    title: 'Create GitHub organization',
    text: '',
    date: new Date(2022, 0, 28),
  },
  {
    title: 'Engine Gin',
    text: '',
    date: new Date(2022, 0, 29),
  },
  {
    title: 'MLK Park',
    text: '',
    date: new Date(2022, 0, 30),
  },
  {
    title: 'Met a new colleague',
    text: '',
    date: new Date(2022, 0, 31),
  },
  {
    title: 'Mozo Moroccan Restaurant',
    text: '',
    date: new Date(2022, 1, 1),
  },
  {
    title: 'Engaged a recruitment consultancy',
    text: '',
    date: new Date(2022, 1, 2),
  },
  {
    title: 'Nerdle',
    text: '+ Mini frikandellen',
    date: new Date(2022, 1, 3),
  },
  {
    title: 'New sofa',
    text: `Booked tickets to Greece
    New takeway, Bao-Bae
    Hired recruiters Conga Group
    Left a Dutch company
    Subscribed to a YouTube channel
    Discovered a new channel: Catalin Miron
    Watched movie Room
    Sprayed sofa protection spray`,
    date: new Date(2022, 1, 4),
  },
  {
    title: 'Zazas restaurant',
    text: `The Tavern cocktail bar
    Met Charlotte and Josh`,
    date: new Date(2022, 1, 5),
  },
  {
    title: 'Run around De Pijp',
    text: `Running through hail
    Watching moguls skiing at Winter Olympics`,
    date: new Date(2022, 1, 6),
  },
  {
    title: 'Don Broco - Amazing Things',
    text: 'UK#1 Album, new band, new album',
    date: new Date(2022, 1, 7),
  },
  {
    title: 'Professional Beard Trim',
    text: '',
    date: new Date(2022, 1, 8),
  },
  {
    title: 'Fly to Greece',
    text: '',
    date: new Date(2022, 1, 9),
  },
  {
    title: 'Parthenon',
    text: `Baba Au Rum
    Japanese-Greek fusion "Nolan"`,
    date: new Date(2022, 1, 10),
  },
  {
    title: 'The Clumsies - World\'s Best Bars #4',
    text: `Climbed Lycabettus Hill
    Traditional Greek Moussaka
    Greek Pale Ale - Blame The Sun`,
    date: new Date(2022, 1, 11),
  },
  {
    title: 'Full body (couple\'s) massage',
    text: 'Panathenaic Stadium',
    date: new Date(2022, 1, 12),
  },
  {
    title: 'Parthenon Marbles',
    text: '',
    date: new Date(2022, 1, 22),
  },
  {
    title: 'Bone Marrow from the bone',
    text: '',
    date: new Date(2022, 1, 14),
  },
  {
    title: 'Brewhouse & Kitchen - Highbury',
    text: 'Beer can chicken',
    date: new Date(2022, 1, 15),
  },
  {
    title: 'Nokkel Team Day',
    text: `Lunch at The Heron
    The Refinery Citypoint
    Met M*****, M***** and S***** (S****) in person`,
    date: new Date(2022, 1, 16),
  },
  {
    title: 'Tested Positive',
    text: '',
    date: new Date(2022, 1, 17),
  },
  {
    title: 'Stay in an Airport hotel',
    text: 'Courtyard by Marriott, LCY',
    date: new Date(2022, 1, 18),
  },
  {
    title: 'The Book of Mormon',
    text: 'All Of Us Are Dead',
    date: new Date(2022, 1, 19),
  },
  {
    title: 'Speed reading',
    text: '',
    date: new Date(2022, 1, 20),
  },
  {
    title: 'One Cup Ozeki Sake',
    text: 'Lego Technic Ducati Panigale V4 R',
    date: new Date(2022, 1, 21),
  },
  {
    title: 'Crosswordle',
    text: `Extended stay at a hotel
    Wear a new T-Shirt (Pi)`,
    date: new Date(2022, 1, 22),
  },
  {
    title: 'Full-build Lego GIF',
    text: `Recover from COVID
    Wear a new T-Shirt (pants and socks)`,
    date: new Date(2022, 1, 23),
  },
  {
    title: 'Royal Victoria Gardens',
    text: `Walk around North Woolwich
    LCY testing centre
    River Kitchen 4.8*`,
    date: new Date(2022, 1, 24),
  },
  {
    title: 'Balvenie Triple Cask 12yo',
    text: '',
    date: new Date(2022, 1, 25),
  },
  {
    title: 'Chorizo dough',
    text: 'De Kromme Haring Wahoo beer growler',
    date: new Date(2022, 1, 26),
  },
  {
    title: 'Alcohol-free beers',
    text: 'Snorkeler\'s Salted IPA, Lowlander Wit bier',
    date: new Date(2022, 1, 27),
  },
  {
    title: 'Ceder\'s Crisp 0.0% Gin',
    text: '',
    date: new Date(2022, 1, 28),
  },
  {
    title: 'Reaction video',
    text: 'Jinjer Pisces',
    date: new Date(2022, 2, 1),
  },
  {
    title: 'Jackfruit burger',
    text: 'Created blue cheese sourdough',
    date: new Date(2022, 2, 2),
  },
  {
    title: 'Chilli Braadworst (pork + vegetarian)',
    text: 'Baked blue cheese sourdough',
    date: new Date(2022, 2, 3),
  },
  {
    title: 'First Video Interview',
    text: 'Fentiman\'s mandarin tonic',
    date: new Date(2022, 2, 4),
  },
  {
    title: 'Breakfast at Bij Paul',
    text: 'Discovered a new cafe for breakfast in de Pijp',
    date: new Date(2022, 2, 5),
  },
];

export default days;